.shimmer-background::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.45) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: shimmer 2s infinite;
  transform: rotate(30deg);
}

@keyframes shimmer {
  0% {
    transform: translate(-50%, -50%) rotate(30deg);
  }
  100% {
    transform: translate(50%, 50%) rotate(30deg);
  }
}

.vibrate-background {
  animation: vibrate 150ms linear infinite both;
}

@keyframes vibrate {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translate(-2px, 2px);
  }
  40% {
    transform: translate(-2px, -2px);
  }
  60% {
    transform: translate(2px, 2px);
  }
  80% {
    transform: translate(2px, -2px);
  }
  100% {
    transform: translate(0);
  }
}

@keyframes jiggle {
  0% {
    transform: translate(0, 0);
  }
  20% {
    transform: translate(1px, -1px);
  }
  40% {
    transform: translate(-1px, 1px);
  }
  60% {
    transform: translateX(1px, -1px);
  }
  80% {
    transform: translateX(-1px, 1px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes nudge {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(1px, -1px);
  }
  100% {
    transform: translate(0, 0);
  }
}
