.ql-font-playfair {
  font-family: 'Playfair Display';
}
.ql-font-poppins {
  font-family: 'Poppins';
}
.ql-font-arial {
  font-family: 'Arial';
}
.ql-font-tinos {
  font-family: 'Tinos';
}
.ql-font-merriweather {
  font-family: 'Merriweather';
}
.ql-font-montserrat {
  font-family: 'Montserrat';
}
.ql-font-helvetica {
  font-family: 'Helvetica';
}
.ql-font-monospace {
  font-family: 'monospace';
}
