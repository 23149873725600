.ft-spinner {
  margin: 0;
  --center: translate(-50%, -50%);

  --spinner: #1eaaf0;
  --outcolor: #1eaaf0;
  --innercolor: #4ec1ea;
  --centercolor: #1eaaf0;

  --spinnerRadius: 100px;
  height: 0;

  &.has-background {
    height: 100%;
  }
}

.center {
  position: absolute;
  width: calc(var(--spinnerRadius) * 0.3);
  height: calc(var(--spinnerRadius) * 0.3);
  background: var(--spinner);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: var(--center);
}
.outer-spin,
.inner-spin {
  position: absolute;
  top: 50%;
  left: 50%;
}
.outer-spin {
  animation: spin 2s linear infinite;
}
.outer-arc,
.inner-arc {
  position: absolute;
  border-radius: 50%;
  border: 4px solid;
}
.outer-arc {
  width: var(--spinnerRadius);
  height: var(--spinnerRadius);
}
.outer-arc_start-a {
  border-color: transparent transparent transparent var(--spinner);
  /*NOTE: the order here very much matters!  */
  transform: var(--center) rotate(65deg);
}
.outer-arc_end-a {
  border-color: var(--spinner) transparent transparent transparent;
  transform: var(--center) rotate(45deg);
}
.outer-arc_start-b {
  border-color: transparent transparent transparent var(--spinner);
  transform: var(--center) rotate(65deg) scale(-1, -1);
}
.outer-arc_end-b {
  border-color: var(--spinner) transparent transparent transparent;
  transform: var(--center) rotate(45deg) scale(-1, -1);
}

.outer-moon-a,
.outer-moon-b {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(var(--spinnerRadius) * 0.15);
  height: calc(var(--spinnerRadius) * 0.15);

  background: var(--spinner);
  border-radius: 50%;
}

.outer-moon-a {
  transform: var(--center) translate(52px, 0);
}

.outer-moon-b {
  transform: var(--center) translate(-52px, 0);
}

.inner-spin {
  animation: spin 700ms linear infinite;
}
.inner-arc {
  width: calc(var(--spinnerRadius) * 0.62);
  height: calc(var(--spinnerRadius) * 0.62);
}
.inner-arc_start-a {
  border-color: transparent transparent transparent var(--innercolor);
  /*NOTE: the order here very much matters!  */
  transform: var(--center) rotate(65deg);
}
.inner-arc_end-a {
  border-color: var(--innercolor) transparent transparent transparent;
  transform: var(--center) rotate(45deg);
}
.inner-arc_start-b {
  border-color: transparent transparent transparent var(--innercolor);
  transform: var(--center) rotate(65deg) scale(-1, -1);
}
.inner-arc_end-b {
  border-color: var(--innercolor) transparent transparent transparent;
  transform: var(--center) rotate(45deg) scale(-1, -1);
}

.inner-moon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(var(--spinnerRadius) * 0.12);
  height: calc(var(--spinnerRadius) * 0.12);

  background: var(--innercolor);
  border-radius: 50%;
}
.inner-moon-a {
  transform: var(--center) translate(33px, 0);
}
.inner-moon-b {
  transform: var(--center) translate(-33px, 0);
}

.animated-text {
  // position: absolute;
  width: var(--spinnerRadius);
  height: var(--spinnerRadius);
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  animation: textanimation 4s linear infinite;
  color: var(--spinner);

  p {
    font-size: 8px; /* Initial font size */
    text-align: center; /* Center the text horizontally */
    color: var(--spinner);
  }
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes textanimation {
  0% {
    font-size: 12px;
  }
  50% {
    font-size: 48px;
  }
  100% {
    font-size: 96px;
  }
}
