@charset "UTF-8";
@use 'sass:map';

// init theme
// @use 'angular-material-css-vars' as mat-css-vars with (
//   $dark-theme-selector: '.darkMode',
//   $light-theme-selector: '.lightMode'
// );

@use '@angular/cdk/overlay-prebuilt.css';
@use './variables' as variables;

@use './materialcustom-theme.scss' as table;
@use './formly-styles.scss' as formly;

/* You can add global styles to this file, and also import other style files */
@use '@ng-select/ng-select/themes/material.theme.css';
@use './overlay-styles.scss' as overlay;
@use './panelclass-styles.scss' as panelclass;
@use './spinner.scss' as spinner;
@use './eyecandy-styles.scss' as eyecandy; // contains shimmer and vibrate animations

/* quill styles */
@use 'quill/dist/quill.snow.css' as quill-snow;
@use './quill-styles.scss' as quill;

@use './ft-mixins.scss' as ft-mixins;

// custom font families for Material
@use '@angular/material' as mat;
// $core-theme: mat.define-theme(
//   $config: (
//     typography: (
//       plain-family: 'Tinos, Poppins, Montserrat, "Open-Sans", "Playfair Display",  sans-serif',
//       brand-family: '"Playfair Display", Poppins, Montserrat, "Open-Sans", sans-serif',
//     ),
//   ),
// );

:root {
  // @include mat.all-component-typographies($core-theme);
  --color-white: hsl(0, 0%, 90%);
  --color-black: hsl(0, 0%, 10%);

  --ft-xsmall-screen: 600px;
  --ft-small-screen: 768px;
  --ft-medium-screen: 992px;
  --ft-large-screen: 1200px;
  --ft-xlarge-screen: 1400px;
  --ft-xxlarge-screen: 1600px;

  --ft-card-radius: 1vmin;
  --ft-container-radius: 1vmin;
  --ft-portrait-spacing: 10px;
  --ft-landscape-spacing: 7px;
  --ft-label-minwidth: 95px;
  --ft-portrait-padding: 5px;
  --ft-landscape-padding: 7px;

  --min-height: 220px;
  --min-width: 220px;

  --tiny-screen: 350px;
  --xsmall-screen: 600px;
  --small-screen: 768px;
  --medium-screen: 992px;
  --large-screen: 1200px;
  --xlarge-screen: 1400px;
  --huge-screen: 3000px;

  --system-text-color: #000;

  --border-radius-medium: 7px;
  --dialog-clamp-width: clamp(var(--min-width), 80vw, 1200px);

  // --mat-sys-primary-container-no-rgb: color-mix(in srgb, var(--mat-sys-primary-container) 100%, transparent 0%);
}

body.dark {
  --system-text-color: #fff;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100vh;
  width: 100%;
  min-height: var(--min-height);
  min-width: var(--min-width);
  scrollbar-width: none;
}

body {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  max-width: 100%;
  max-height: 100%;
  min-height: var(--min-height);
  min-width: var(--min-width);

  font-family: Tinos, 'Playfair Display', 'Merriweather', Poppins, Montserrat, 'Open Sans', sans-serif;
  font-size: clamp(0.8rem, 0.2vw + 0.857rem, 1.29rem);
  --font-size-core: clamp(0.8rem, 0.2vw + 0.857rem, 1.29rem);
  --font-size-fluid: clamp(0.8rem, 1.7vw, 1.3rem);

  overflow-y: hidden;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;

  --sidbar-width: 220px;
  --sidebar-narrow: 60px;
  --sidebar-closed-width: 0px;

  --navbar-image-width: 60px;
  --navbar-image-width-max: 100px;
  --navbar-image-width-min: 32px;
  --navbar-icon-font: Merriweather, 'Open Sans', sans-serif;

  --navbar-height: 72px;
  --navbar-height-large: 108px;

  --search-button-height: 2rem;

  --navbar-search-height: 52px;
  --navbar-search-radius: 3vh;

  --navbar-border: 1px solid rgba(100, 100, 100, 0.3);
}

body::-webkit-scrollbar {
  display: none;
}

body::before {
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  height: 2px;
  filter: blur(2px);
  z-index: 10;
}

.cursive-font {
  @include ft-mixins.cursive-font;
}

.heading-font {
  @include ft-mixins.heading-font;
}

.body-font {
  @include ft-mixins.body-font;
}

// system color classes
.dark-text {
  color: var(--color-black) !important;
}

.light-text {
  color: var(--color-white) !important;
}

.system-color {
  color: var(--color-black);
}

.dark {
  .system-color {
    color: var(--color-white);
  }
}

.app-background {
  background-color: var(--mat-sys-surface);
}
.surface-color {
  color: var(--mat-sys-surface);
}
.on-surface-color {
  color: var(--mat-sys-on-surface);
}

.mat-app-background {
  background-color: var(--mat-sys-surface);
}

.background-primary {
  background-color: var(--mat-sys-primary);
}
.primary-color {
  color: var(--mat-sys-primary);
}
.on-primary-color {
  color: var(--mat-sys-on-primary);
}

.background-accent {
  background-color: var(--mat-sys-tertiary);
}
.accent-color {
  color: var(--mat-sys-tertiary);
}
.on-accent-color {
  color: var(--mat-sys-on-tertiary);
}

.background-error {
  background-color: var(--mat-sys-error);
}
.error-color {
  color: var(--mat-sys-error);
}
.on-error-color {
  color: var(--mat-sys-on-error);
}

.ft-mat-background {
  background-color: var(--mat-sys-surface) !important;
}

.gm-style-iw {
  background-color: var(--mat-sys-error);
}

h1,
.h1 {
  margin: 0;
  font-family: 'Playfair Display', 'Open Sans', sans-serif;
}

h2,
.h2 {
  margin: 0;
}

.index-html {
  font-family: Tangerine, cursive;
  font-weight: 500;
  box-sizing: border-box;
  transition: all 1s ease-in-out !important;
  font-size: clamp(22px, 4vw, 72px);
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: var(--mat-sys-tertiary-fixed);
  &.message {
    position: fixed;
    top: 2vh;
    text-align: center;
    left: 0;
    right: 0;
    transition: all 0.7s ease-in-out;
    height: clamp(50px, 1rem, 20vh);
  }
  &.title {
    text-align: center;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    text-align: center;
    transform: translateY(-50%);
    transition: all 0.7s ease-in-out;
    height: clamp(150px, 1rem, 70vh);
  }
}

.hide-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow: -moz-scrollbars-none;
  &::-webkit-scrollbar {
    width: 0px;
    display: none;
    background: transparent; /* Chrome/Safari/Webkit */
    -webkit-appearance: none;
  }
}

.sticky {
  position: sticky;
  position: -webkit-sticky; /* Safari */
  top: 0;
}

.hide-element {
  width: 0px !important;
  height: 0px !important;
  display: none !important;
}

.not-visible {
  width: 0px !important;
  height: 0px !important;
  visibility: hidden !important;
}

.flash-text {
  text-shadow:
    #bbb 0 0 1px,
    #fff 0 -1px 2px,
    #fff 0 -3px 2px,
    rgba(0, 0, 0, 0.8) 0 2px 2px;
  transition: margin-left 0.3s cubic-bezier(0, 1, 0, 1);
}

.flash-text:hover {
  text-shadow:
    #111 0 0 1px,
    rgba(255, 255, 255, 0.1) 0 1px 3px;
}

.clickable:hover {
  cursor: pointer;
}

.blur-20 {
  filter: blur(20px);
}

app-root {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
}

.app-component-page {
  max-height: 100%;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.page-controls {
  @include ft-mixins.background-surface;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 7px 20px;
  width: 100%;

  .page-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 7px;
    align-items: center;

    .button-instance {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 7px;
    }
  }

  .page-totals {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.page-content {
  flex: 1;
  max-height: 100%;
  display: flex;
  flex-direction: row;

  overflow-y: hidden;
}

.pre-line {
  white-space: pre-line;
}

.no-wrap {
  white-space: nowrap;
}

.strokeme {
  color: white;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    -1px 1px 0 #000,
    2px 2px 0 #000;
}

.strong {
  font-weight: 600;
}

.weak {
  font-weight: lighter;
}

.font-size-core {
  font-size: var(--font-size-core);
}

.font-size-fluid {
  font-size: var(--font-size-fluid);
}

.font-small {
  font-size: small;
}

.font-smaller {
  font-size: smaller;
}

.font-xsmall {
  font-size: x-small;
}

.font-large {
  font-size: large;
}

.font-larger {
  font-size: larger;
}

.font-xlarge {
  font-size: x-large;
}

[cdkdraghandle] {
  cursor: move;
}

.width-100,
.w-100,
.full-width {
  width: 100% !important;
}

.height-100,
.h-100,
.full-height {
  height: 100% !important;
}

// buttons
.button-instance {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;

  button {
    align-self: center;
  }

  p {
    margin: auto;
    align-self: center;
  }
}

// bullet points
.bullet-point::before {
  content: '\2022';
  margin-right: 3px;
}

.bullet-point-primary::before {
  @include ft-mixins.primary-color;
  content: '\2022';
  margin-right: 3px;
}

.bullet-point-accent::before {
  @include ft-mixins.accent-color;
  content: '\2022';
  margin-right: 3px;
}

.bullet-point-warn::before {
  @include ft-mixins.error-color;
  content: '\2022';
  margin-right: 3px;
}

.delete-spacer {
  flex: 1;
}

// quill overrides
.artefact-rich-text {
  background-color: var(--mat-sys-surface);
  .ql-container .ql-editor {
    max-height: 100%;
    background-color: var(--mat-sys-surface);
    border-radius: clamp(3px, 1vw, 7px);
    p {
      img {
        width: auto;
        max-width: 100%;
        max-height: 80vh;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &.lt-medium {
    .ql-container .ql-editor {
      p {
        span + img {
          max-width: 60vw;
        }
      }
    }
  }

  &.is-xsmall {
    .ql-container .ql-editor {
      p {
        span + img {
          float: none;
          margin: auto;
          text-align: center;
          max-width: 100%;
          object-fit: contain;
          object-position: center;
        }
      }
    }
  }
}

/* Quill overrides */

quill-editor {
  background-color: #fff;
}

.ql-editor,
.ql-container {
  p > img:first-child {
    float: left;
    margin-right: 5px;
    max-width: calc(100% - 400px);
  }

  p {
    span + img {
      float: right;
      max-width: calc(100% - 400px);
      margin-left: 5px;
    }
  }
}

.ql-container {
  .ql-editor {
    p + h1,
    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6 {
      display: block;
      clear: both;
      padding-top: 7px;
    }
  }
}

.ql-editor p {
  @include ft-mixins.surface-color;
}

.ql-editor[contenteditable='true'] {
  @include ft-mixins.background-surface;
}

.ngx-quill-view-html.ql-container {
  .ql-editor ol,
  .ql-editor ul {
    padding-inline-start: 40px;
    margin-block-start: 0.1em;
    margin-block-end: 0.1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .ql-editor ol li,
  .ql-editor ul li {
    padding-left: 0.1em;
  }

  .ql-editor ol li,
  .ql-editor ol {
    list-style-type: decimal !important;
  }

  .ql-editor ul li,
  .ql-editor ul {
    list-style-type: disc !important;
  }

  .ql-editor p {
    margin-bottom: 0 !important;
    min-height: 1rem;
  }
}

.ql-font-cursive {
  font-family: Tangerine, cursive;
}

.ql-font-futura {
  font-family: 'Merriweather';
}

.ql-font-monaco {
  font-family: 'Playfair Display';
}

.ql-font-helvetica {
  font-family: 'Playfair Display';
}

.ql-font-arial {
  font-family: 'Open Sans';
}

.ql-font-monospace {
  font-family: monospace;
}

section.ql-section {
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .ql-toolbar button[title]::after {
//   content: attr(title);
//   position: absolute;
//   background: #333;
//   color: #fff;
//   padding: 5px;
//   border-radius: 3px;
//   white-space: nowrap;
//   display: none;
//   z-index: 10;
// }

// .ql-toolbar button:hover[title]::after {
//   display: block;
// }

.navigation-component-button {
  height: clamp(100%, 52px, 52px);
  border-radius: 1vmin;
}

.rotate-y {
  animation: rotatey 2s ease-in infinite; /* Define the animation */
}

.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.flex-8 {
  flex: 8;
}
.flex-9 {
  flex: 9;
}
.flex-10 {
  flex: 10;
}

.flex-wrap {
  flex-wrap: wrap;
}

.no-flex-wrap {
  flex-wrap: nowrap;
}

.cursive-font {
  @include ft-mixins.cursive-font;
}
.heading-font {
  @include ft-mixins.heading-font;
}
.body-font {
  @include ft-mixins.body-font;
}
.monospace-font {
  @include ft-mixins.monospace-font;
}

@keyframes rotatey {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.rotate-x {
  animation: rotatex 2s ease-in infinite; /* Define the animation */
}

@keyframes rotatex {
  0% {
    transform: rotateX(0deg);
  }
  50% {
    transform: rotateX(180deg);
  }
  100% {
    transform: rotateX(360deg);
  }
}

.outline-circle {
  animation: anim-outline 2s ease-in infinite; /* Define the animation */
  border-radius: 50%;
}

@keyframes anim-outline {
  0% {
    border: 1px solid color-mix(in srgb, var(--mat-sys-primary) 50%, transparent 50%);
  }
  50% {
    border: 1px solid color-mix(in srgb, var(--mat-sys-primary) 75%, transparent 25%);
  }
  100% {
    border: 1px solid color-mix(in srgb, var(--mat-sys-primary) 100%, transparent 0%);
  }
}

// minimum width required - hide when not minimum size
@media only screen and (max-width: var(--min-width)) {
  .show-min {
    display: none !important;
  }
}

// small - only show when at least small
@media only screen and (max-width: var(--small-screen)) {
  .show-small {
    display: none !important;
  }
}

// medium - only show when at least medium
@media only screen and (max-width: var(--medium-screen)) {
  .show-medium {
    display: none !important;
  }
}

// large - only show when at least large
@media only screen and (max-width: var(--large-screen)) {
  .show-large {
    display: none !important;
  }
}

// xlarge - only show when at least xlarge
@media only screen and (max-width: var(--xlarge-screen)) {
  .show-xlarge {
    display: none !important;
  }
}

// xxlarge - only show when at least xxlarge
@media only screen and (max-width: var(--xxlarge-screen)) {
  .show-xxlarge {
    display: none !important;
  }
}

::view-transition-old(root) {
  // TODO animation definition for leaving page
  animation-duration: 300ms;
}

::view-transition-new(root) {
  // TODO animation definition for new page
  animation-duration: 300ms;
}

cross-fade-blur-span {
  display: block;
  text-shadow: 0 0 8px #fff;
  animation-name: cross-fade-blur;
  animation-duration: 4s;
  animation-iteration-count: 1;
  @for $i from 1 through 10 {
    &:nth-child(#{$i}) {
      animation-delay: $i * 0.04s;
    }
  }
}
@keyframes cross-fade-blur {
  0% {
    opacity: 0;
    filter: blur(1rem);
    transform: translate3d(0, 3rem, 0) rotate3d(0.25, -1, 0, 66.6deg);
  }
  20%,
  60% {
    opacity: 1;
    filter: blur(0px);
    transform: translate3d(0, 0, 0);
  }
  80%,
  100% {
    opacity: 0;
    filter: blur(1rem);
    transform: translate3d(0, -3rem, 0) rotate3d(-0.25, 1, 0, 66.6deg);
  }
}

.spin-animation-slow {
  animation: spinme 3s linear infinite !important;
}

@keyframes spinme {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.expand-from-half {
  animation: expandFromHalf 300ms linear;
}

.expand-animation {
  animation: expandme 300ms ease-in;
}

.slow-animation {
  animation-duration: 7s !important;
  animation-timing-function: ease-in !important;
}

@keyframes expandFromHalf {
  from {
    transform: scale(75%);
  }
}

@keyframes expandme {
  0% {
    transform: scale(0.1);
    opacity: 1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes slide-from-right {
  from {
    transform: translateX(30px);
  }
}

@keyframes slide-to-right {
  to {
    transform: translateX(30px);
  }
}

@keyframes slide-to-left {
  to {
    transform: translateX(-30px);
  }
}

// Slide-in animation from the left
@keyframes slide-in-from-left {
  from {
    transform: translateX(-100%); // Start completely off-screen to the left
    opacity: 0; // Start transparent
  }
  to {
    transform: translateX(0); // End at its normal position
    opacity: 1; // End fully opaque
  }
}

// Slide-out animation to the left
@keyframes slide-out-to-left {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}

@keyframes rotate-out {
  to {
    transform: rotate(90deg);
  }
}
@keyframes rotate-in {
  from {
    transform: rotate(-90deg);
  }
}

::view-transition-old(root) {
  animation: 300ms cubic-bezier(0.4, 0, 1, 1) both fade-out;
  /* 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-left; */
}

::view-transition-new(root) {
  animation: 300ms cubic-bezier(0, 0, 0.2, 1) 90ms both fade-in;
  /* 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right; */
}

::view-transition-new(main-nav):only-child {
  animation: 1000ms cubic-bezier(0, 0, 0.2, 1) both fade-in;
  /* 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right; */
}

::view-transition-old(main-nav):only-child {
  animation: 1150ms cubic-bezier(0.4, 0, 1, 1) both fade-out;
  /* 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-right; */
}

::view-transition-new(element-transition) {
  animation: 1000ms cubic-bezier(0, 0, 0.2, 1) both fade-in;
  /* 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-from-right; */
}

::view-transition-old(element-transition) {
  animation: 1150ms cubic-bezier(0.4, 0, 1, 1) both fade-out;
  /* 300ms cubic-bezier(0.4, 0, 0.2, 1) both slide-to-right; */
}

::view-transition-old(count),
::view-transition-new(count) {
  animation-duration: 1200ms;
  animation-name: -ua-view-transition-fade-in, rotate-in;
}
::view-transition-old(count) {
  animation-name: -ua-view-transition-fade-out, rotate-out;
}

.max-w-fit {
  max-width: fit-content;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column !important;
}
