/* You can add global styles to this file, and also import other style files */
@use '@ft/lib/styles/core-styles.scss';

/* app theme */
@use '@angular/material' as mat;
@use './dancecomps_theme-colors.scss' as app-theme;

$base-theme: mat.define-theme(
  (
    color: (
      theme-type: light,
      primary: app-theme.$primary-palette,
      tertiary: app-theme.$tertiary-palette,
    ),
    typography: (
      plain-family: 'Montserrat, Poppins, Tinos, "Open-Sans", "Playfair Display",  sans-serif',
      brand-family: '"Playfair Display", Poppins, Montserrat, "Open-Sans", sans-serif',
    ),
    density: (
      scale: 0,
    ),
  )
);

html {
  --light-border: rgba(200, 200, 200, 0.3);
  color-scheme: light;

  /* Primary palette variables */
  @include mat.theme(
    (
      color: (
        theme-type: light,
        primary: app-theme.$primary-palette,
        tertiary: app-theme.$tertiary-palette,
      ),
      typography: (
        plain-family: 'Merriweather, Poppins, Tinos, Montserrat, "Open-Sans", "Playfair Display",  sans-serif',
        brand-family: '"Playfair Display", Poppins, Montserrat, "Open-Sans", sans-serif',
      ),
      density: (
        scale: 0,
      ),
    )
  );

  // Use high contrast light theme colors when users prefer contrast
  @media (prefers-contrast: more) {
    @include app-theme.high-contrast-overrides(light);
  }
}

body.dark {
  --light-border: rgba(10, 10, 10, 0.3);

  color-scheme: dark;
  @include mat.theme(
    (
      color: (
        theme-type: dark,
        primary: app-theme.$primary-palette,
        tertiary: app-theme.$tertiary-palette,
      ),
      // typography: monospace,
       // density: 0,
    )
  );

  // Use high contrast dark theme colors when users prefer contrast
  @media (prefers-contrast: more) {
    @include app-theme.high-contrast-overrides(dark);
  }
}

ft-metric-card {
  // box-shadow: var(--mat-sys-level5);
  @include mat.card-overrides(
    (
      // box-shadow: var(--mat-sys-level5),
      // subtitle-text-color: orange,
      // outlined-container-color: var(--mat-sys-surface),
      // elevated-container-color: var(--mat-sys-surface-bright),
      // elevated-container-elevation: red,
       // title-text-size: 44px,
    )
  );
}
