@use './ft-mixins.scss' as ft-mixins;
// profile button menu
.profile-button-menu {
  color: var(--mat-sys-on-surface);
  .user-info-menu {
    color: var(--mat-sys-on-surface);
    padding: 12px;
    .profile-page-menu-items {
      display: flex;
      flex-direction: column;
    }
  }

  .theme-toggle {
    padding: 7px 0px;
  }

  .footer {
    font-size: xx-small;
    opacity: 0.8;
    line-height: 0.5rem;
    justify-content: flex-end;
    position: relative;
    bottom: 0;
    right: 0;
    white-space: normal;
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }
}

// snackbar classes
ft-snackbar {
  background-color: var(--mat-sys-surface);
  border-color: var(--mat-sys-outline);
  color: var(--mat-sys-on-surface);
  button[matsnackbaraction] {
    color: var(--mat-sys-on-surface);
  }
}

/**
 * snackbar classes
 */
.mdc-snackbar.ft-snackbar,
.ft-snackbar {
  white-space: pre-line;

  &.ft-snackbar-info {
    .mdc-snackbar__surface {
      background-color: var(--mat-sys-primary-container);
      color: var(--mat-sys-on-primary-container);
      border-color: var(--mat-sys-outline);
      button[matsnackbaraction] {
        color: var(--mat-sys-on-primary-container);
      }
    }
  }

  &.ft-snackbar-success {
    .mdc-snackbar__surface {
      background-color: rgba(8, 170, 70, 0.95);
      color: rgba(242, 243, 244, 0.95);
      button[matsnackbaraction] {
        color: rgba(242, 243, 244, 0.95);
      }
    }
  }

  &.ft-snackbar-warning {
    .mdc-snackbar__surface {
      background-color: rgba(244, 226, 96, 0.95);
      color: rgba(42, 43, 44, 0.95);
      button[matsnackbaraction] {
        color: rgba(42, 43, 44, 0.95);
      }
    }
  }

  &.ft-snackbar-error {
    .mdc-snackbar__surface {
      background-color: rgba(250, 42, 61, 0.95);
      color: rgba(42, 43, 44, 0.95);
      button[matsnackbaraction] {
        color: rgba(42, 43, 44, 0.95);
      }
    }
  }
}

//
// paste panel
//
.ft-paste-panel {
  border: var(--mat-sys-outline);
  border-radius: var(--border-radius-medium);
  box-sizing: border-box;

  div[mat-dialog-title] {
    display: flex;
    justify-content: stretch;
    align-items: center;
  }
}

// filter panel
.mat-mdc-dialog-panel.ft-filter-panel-xsmall {
  position: fixed !important;
  left: 0;
  right: 0;
  top: var(--navbar-height);
  bottom: 0;
  width: 100vw !important;
  max-width: 100vw !important;
  box-sizing: border-box;
  overflow: hidden !important;
}

// .filter-menu {
// .mdc-text-field--filled:not(.mdc-text-field--disabled) {
//   background-color: inherit;
// }
// }

.filter-menu.filter-menu-panel {
  overflow-y: visible;
}

.filter-menu-panel {
  background-color: transparent !important;

  .mat-mdc-card-content:first-child {
    padding-top: 2px;
  }

  .mat-mdc-menu-content {
    padding: 0px;
  }

  .filter-menu-card-content {
    padding: 2px;
    padding-bottom: 0px;
  }

  .filter-menu-card-actions {
    padding: 0 2px;
  }
}

mat-card.panel-card {
  border-radius: var(--ft-card-radius);
  background-color: var(--mat-sys-surface-container-lowest);
  color: var(--mat-sys-on-surface);
  width: 100%;
  border: 1px solid var(--mat-sys-outline);

  //
  mat-card-header.card-header {
    flex: 1;
    display: flex;
    justify-content: space-between;

    padding: clamp(3px, 1vw, 7px);
    border-top-left-radius: var(--ft-card-radius);
    border-top-right-radius: var(--ft-card-radius);

    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
      object-position: top;
      border-radius: 3px;
    }
    mat-card-title {
      font: var(--mat-sys-title-medium);
      @include ft-mixins.heading-font;
    }
    mat-card-subtitle {
      font: var(--mat-sys-title-small);
      @include ft-mixins.body-font;
      margin-left: clamp(3px, 1rem, 12px);
      margin-right: clamp(3px, 1rem, 12px);
    }

    .mat-mdc-card-header-text {
      flex: 1;
    }
  }

  mat-card-content {
    display: flex;
    width: 100%;
    font: var(--mat-sys-body-small);
    @include ft-mixins.body-font;
  }

  &.primary-background {
    background-color: var(--mat-sys-primary-container);
    color: var(--mat-sys-on-primary-container);
    mat-card-header {
      mat-card-title,
      mat-card-subtitle {
        color: inherit;
      }
    }

    mat-card-content {
      color: inherit;
    }
  }

  &.transparent-background {
    background-color: transparent;
    backdrop-filter: contrast(0.5);
    color: var(--mat-sys-on-surface);
    mat-card-header {
      mat-card-title,
      mat-card-subtitle {
        color: inherit;
      }
    }

    mat-card-content {
      color: inherit;
    }
  }
}

mat-card[addAlternatingBackground] {
  position: relative;
  border: 1px solid var(--mat-sys-outline);

  mat-card-header {
    mat-card-title,
    mat-card-subtitle {
      font-weight: 600;
      // color: inherit;
    }
  }

  mat-card-content {
    // color: inherit;
    font-weight: 700;
  }
}

.mat-mdc-card-outlined.no-border {
  border-width: 0px;
}
