@use 'sass:map';
@use '@angular/material' as mat;

// @mixin formlyStyles($theme) {
formly-form {
  .display-flex-row {
    display: flex;
    gap: 7px;
    align-items: center;

    max-width: 100%;
    flex-wrap: wrap;
    word-wrap: break-word;

    div {
      white-space: initial;
      word-wrap: break-word;
    }

    legend {
      white-space: pre;
    }

    .no-wrap {
      legend,
      .ft-output-text {
        text-wrap: nowrap;
        white-space: nowrap;
      }
    }

    formly-field {
      display: flex;

      &.ft-gap {
        .ft-repeat-content {
          gap: 10px;
        }
      }

      &.ft-gap-large,
      &.ft-gap-big {
        .ft-repeat-content {
          gap: 20px;
        }
      }

      &.ft-gap-small {
        .ft-repeat-content {
          gap: 5px;
        }
      }
    }

    formly-wrapper-mat-form-field {
      width: 100%;
    }

    button {
      cursor: pointer;
    }

    &.align-flex-start {
      align-items: flex-start;
    }
  }

  .display-flex-column {
    display: flex;
    flex-direction: column;

    formly-field {
      display: flex;
    }

    formly-wrapper-mat-form-field {
      width: 100%;
    }

    button {
      cursor: pointer;
    }
  }

  formly-field > formly-group.display-flex-row {
    align-items: flex-end;
  }

  formly-field > formly-group.display-flex-row.align-center {
    align-items: center;
  }

  > formly-field > formly-group {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  // duplicated values
  formly-field.ft-duplicated {
    label {
      color: var(--mat-sys-secondary) !important;
    }

    input {
      color: var(--mat-sys-primary) !important;
    }
  }
}

formly-form,
.ft-array-flex-row {
  .flex-1,
  ft-array-field.flex-1 {
    flex: 1;
  }
  .flex-2,
  ft-array-field.flex-2 {
    flex: 2 !important;
  }
  .flex-3,
  ft-array-field.flex-3 {
    flex: 3 !important;
  }
  .flex-4,
  ft-array-field.flex-4 {
    flex: 4 !important;
  }
  .flex-5,
  ft-array-field.flex-5 {
    flex: 5 !important;
  }
  .flex-10,
  ft-array-field.flex-10 {
    flex: 10 !important;
  }
  .flex-auto,
  ft-array-field.flex-auto {
    flex: auto !important;
  }
}

.ft-flex-column {
  flex-direction: column;
}

// **
// ** repeat field type
// **
.ft-repeat-group:first-child {
  border-top: 3px solid rgba(0, 0, 0, 0.5);
}

.ft-repeat-group {
  box-sizing: border-box !important;
  border-bottom: 3px solid rgba(0, 0, 0, 0.5);
  padding: 7px 15px;

  display: flex;
  flex-direction: column;
  // justify-content: stretch;
  align-items: stretch;
  width: 100%;
  max-width: 100%;

  .ft-add-button {
    margin-top: 7px;
    margin-bottom: 7px;

    button {
      padding: 2px 3px;
      border-radius: 3px;
      box-shadow: none;
      border: none;
    }
  }
}

.ft-repeat-heading {
  display: flex;
  flex-direction: row;
  gap: 7px;

  padding-top: 7px;
  margin-top: 7px;
  margin-bottom: 3px;
}

.ft-repeat-content {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 100%;
  position: relative;
  overflow-x: hidden;
  flex-wrap: wrap;

  .ft-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    max-width: 100%;
    position: relative;

    margin-bottom: 5px;

    .ft-array-flex-row {
      flex: 1;
    }

    > .ft-array-field {
      flex: 1;
    }

    formly-field.ft-array-field {
      &.width-small {
        width: 140px;
      }
    }

    formly-field.ft-border-bottom:not(:last-child) {
      border-bottom-color: var(--mat-sys-outline);
      border-bottom-style: solid;
      border-bottom-width: clamp(3px, 2vh, 5px);
    }

    .ft-array-flex-field {
      flex: 1;
      display: flex !important;
      flex-direction: row;
      max-width: 100%;
      flex-wrap: wrap;

      .ft-array-flex-row {
        // width: 100%;
        flex: 1;
        display: flex !important;
        flex-direction: row;
        justify-content: stretch;
        gap: 5px;
        flex-wrap: wrap;
        max-width: 100%;

        formly-field {
          flex: 1;
        }
      }
    }

    > .ft-array-flex-field.flex-100 {
      formly-wrapper-mat-form-field {
        width: 100%;
      }
    }
  }

  // .ft-row:not(:last-child) {
  .ft-row:not([data-hidden-row]):not(:last-child) {
    padding-bottom: 5px;
    border-bottom: 5px double var(--mat-sys-outline);
  }

  .ft-repeat-field {
    display: flex;
    flex-direction: row;
    flex: 1;

    max-width: 100%;
    overflow-x: hidden;

    formly-wrapper-mat-form-field {
      width: 100%;
    }
  }

  .ft-repeat-row-buttons {
    display: flex;
    flex-direction: column;
    gap: 3px;
    margin: auto;
  }

  .ft-align-items-baseline {
    align-items: baseline;
  }
}

formly-field.flex-row {
  ft-repeat-section {
    .ft-repeat-group {
      .ft-repeat-content {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        border: none;
        .ft-row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          border: none;

          .ft-repeat-row-buttons {
            flex: 100;
          }
        }

        .ft-row:not(:last-child) {
          padding-right: 12px;
        }
      }

      .ft-repeat-content::after {
        content: ''; /* Empty content */
        flex: 100;
        height: 100%;
      }
    }
  }
}

formly-field.no-wrap {
  formly-field-mat-radio {
    text-wrap: nowrap;
    white-space: nowrap;
  }
}

.formly-actions {
  padding: 5px;
  align-items: center;
}

// **
// ** output field type
// **

ft-output-type,
.ft-output-type {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 7px;

  .ft-output-field {
    &-flex {
      display: flex;
      gap: 5px;
    }
  }

  .ft-output-heading {
    display: flex;
    flex-direction: row;
    padding: 0px;

    filter: contrast(0.4);
  }

  .ft-output-content {
    display: flex;
    flex-direction: row;
    padding: 0px;
  }

  .ft-output-date,
  .ft-output-text,
  .ft-output-number,
  .ft-output-boolean {
    font-weight: 600;
  }
}

.ft-content-nowrap {
  .ft-output-text:not(:last-of-type) {
    margin-bottom: 0.5rem;
  }
  .ft-output-content {
    flex-wrap: wrap;
  }
}

// medium - only show when at least medium
@media only screen and (max-width: var(--medium-screen)) {
  formly-form {
    .display-flex-row {
      flex-direction: column;
      align-items: flex-start;
      gap: 3px;
    }

    formly-group.display-flex-row {
      align-items: stretch;
    }
  }

  formly-form formly-field > formly-group.display-flex-row {
    align-items: stretch;
  }
}

@media only screen and (max-width: var(--small-screen)) {
  formly-form {
    .display-flex-row {
      align-items: stretch;
      gap: 1px;
    }

    ft-output-type {
      .ft-output-field.ft-output-field-flex {
        flex-wrap: wrap;
      }
    }
  }

  formly-form formly-field > formly-group.display-flex-row {
    align-items: stretch;
  }
}
