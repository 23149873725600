@use 'sass:map';
@use '@angular/material' as mat;

// Customize the entire app. Change :root to your selector if you want to scope the styles.
:root {
  @include mat.menu-overrides(
    // (
    //   container-color: orange,
     // )
  );

  --mat-dialog-container-small-max-width: 100vw;
  --mat-dialog-container-max-width: 100vw;

  .navbar {
    box-shadow:
      0px 3px rgba(0, 0, 0, 0.1),
      inset 0px -7px 10px -5px rgba(0, 0, 0, 0.5);
  }

  div[role='listbox'] {
    border: 1px solid var(--mat-sys-outline);
    border-radius: var(--border-radius-medium);
    max-height: 205px !important;
  }
  ng-dropdown-panel[role='listbox'] {
    border-radius: var(--border-radius-medium);
  }

  .mat-mdc-table-sticky {
    filter: brightness(92%);
  }

  mat-form-field.full-width {
    width: 100%;
  }

  .mat-mdc-text-field-wrapper {
    align-items: flex-end;
  }

  /* Tooltip */
  .mat-tooltip,
  .tooltip-inner,
  .mdc-tooltip__surface,
  .mdc-tooltip {
    background-color: var(--mat-sys-primary) !important;
    color: var(--mat-sys-on-primary) !important;
    border-radius: 3px;
  }

  .mdc-snackbar.mat-mdc-snack-bar-container.mdc-snackbar--open {
    display: flex;
    overflow-x: hidden;
    flex-wrap: wrap;
    text-wrap: wrap;
  }

  .mdc-snackbar {
    font-style: italic;
    white-space: pre;
  }

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
    .mat-form-field-underline {
      display: none;
    }
  }

  .mat-mdc-checkbox.dark-label {
    .mdc-label {
      color: var(--mat-sys-on-surface);
    }
    .mdc-checkbox {
      color: var(--mat-sys-on-surface);
    }
    .mdc-checkbox__background {
      border-color: var(--mat-sys-surface-dim);
    }
  }

  .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    display: none;
  }

  .mat-mdc-dialog-surface.mdc-dialog__surface {
    box-sizing: border-box;
  }

  // material card overrides
  .mat-mdc-card-header.flex-1 {
    .mat-mdc-card-header-text {
      flex: 1;
    }
  }

  .mat-mdc-card-header.flex-row {
    .mat-mdc-card-header-text {
      display: flex;
      gap: clamp(1vw, 1vw, 12px);
      align-items: center;
    }

    &.space-between {
      justify-content: space-between;
    }
  }

  // form field overrides

  mat-form-field.no-ripple {
    .mat-input-underline,
    .mdc-line-ripple::after,
    .mdc-line-ripple::after,
    .mdc-line-ripple {
      display: none !important;
    }
  }

  // search-bar overrides
  mat-form-field.search-bar-form-field {
    background-color: transparent;

    .mat-mdc-text-field-wrapper.mdc-text-field {
      padding-left: 2px;
      padding-right: 2px;
      background-color: transparent !important;

      .search-box {
        input {
          margin: 2px;
        }
      }
    }

    .mdc-text-field:hover {
      background-color: transparent !important;
      border: none;
    }

    .mat-mdc-text-field-wrapper.mdc-text-field:hover {
      background-color: transparent !important;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent !important;
    }
  }

  // rounded softly
  .ft-array-field {
    .mat-mdc-text-field-wrapper.mdc-text-field {
      border-radius: 5px;
    }
  }

  // FT panel class
  .ft-panel {
    border: var(--mat-sys-outline);
    border-radius: var(--border-radius-medium);
    box-sizing: border-box;

    div[mat-dialog-title] {
      display: flex;
      justify-content: stretch;
      align-items: center;
    }
  }

  // cdk overlay
  @media only screen and (max-width: var(--small-screen)) {
    .cdk-overlay-pane {
      max-width: 100vw;

      &.mat-mdc-dialog-panel {
        max-width: 100vw;
      }
    }
  }

  @media only screen and (min-width: var(--small-screen)) {
    .cdk-overlay-container .cdk-overlay-pane {
      max-width: 100vw !important;
    }
  }

  // **** NG Select
  ng-select.ng-touched.ng-invalid {
    .ng-placeholder {
      color: var(--mat-sys-on-error) !important;
    }
  }

  // borders
  .rounded-xlarge {
    border-radius: 22px;
    overflow: hidden;
  }

  .rounded-large {
    border-radius: 12px;
    overflow: hidden;
  }
  .rounded-medium {
    border-radius: 7px;
    overflow: hidden;
  }
  .rounded-small {
    border-radius: 5px;
    overflow: hidden;
  }
  .rounded-tiny {
    border-radius: 3px;
    overflow: hidden;
  }

  mat-card.elevation-low {
    box-shadow: var(--mat-sys-level1);
  }

  mat-card.elevation-medium {
    box-shadow: var(--mat-sys-level3);
  }

  mat-card.elevation-high {
    box-shadow: var(--mat-sys-level5);
  }
}
