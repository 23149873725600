@use '@angular/material' as mat;
/* SCSS */
@mixin cursive-font {
  font-family: 'Tangerine', cursive;
}

@mixin heading-font {
  font-family: 'Playfair Display', 'Open Sans', Tinos, sans-serif;
}

@mixin body-font {
  font-family: Tinos, Poppins, 'Open Sans', 'Playfair Display', sans-serif;
}

@mixin monospace-font {
  font-family: monospace;
}

@mixin background-surface {
  background-color: var(--mat-sys-surface);
}

@mixin surface-color {
  background-color: var(--mat-sys-surface);
}

@mixin background-primary {
  background-color: var(--mat-sys-primary);
}

@mixin primary-color {
  color: var(--mat-sys-primary);
}

@mixin on-primary-color {
  color: var(--mat-sys-on-primary);
}

@mixin background-accent {
  background-color: var(--mat-sys-tertiary);
}

@mixin accent-color {
  color: var(--mat-sys-tertiary);
}

@mixin on-accent-color {
  color: var(--mat-sys-on-tertiary);
}

@mixin background-error {
  background-color: var(--mat-sys-error);
}

@mixin error-color {
  color: var(--mat-sys-error);
}

@mixin on-error-color {
  color: var(--mat-sys-on-error);
}
