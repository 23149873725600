// --tiny-screen    | `Tiny`             | `(max-width: 349.98px)`
// --xsmall-screen  | `XSmall`           | `(max-width: 599.98px)`
// --small-screen   | `Small`            | `(min-width: 600px) and (max-width: 759.98px)`
// --medium-screen  | `Medium`           | `(min-width: 960px) and (max-width: 1279.98px)`
// --large-screen   | `Large`            | `(min-width: 1280px) and (max-width: 1919.98px)`
// --xlarge-screen  | `XLarge`           | `(min-width: 1920px)`
// --huge-screen    | `Huge`             | `(min-width: 3000px)`

// virtual scroll
.width-100-pct {
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
    width: 100%;
  }
}

// menu styles
.collapsed-menu,
.ft-collapsed-menu,
.mat-mdc-menu-panel,
.collapsed-menu.mat-mdc-menu-panel {
  // background-color: var(--mat-sys-primary-container) !important;
  max-width: 95vw;
}

.mat-mdc-menu-panel {
  .child-links {
    button.activeMenuItem {
      filter: invert(1);
    }
  }
}

.ng-dropdown-panel[role='listbox'] {
  background: var(--mat-sys-surface);
}

// dialog styles

.ft-dialog-component {
  border-radius: var(--mdc-dialog-container-shape, var(--mat-sys-corner-extra-large, 4px));
  overflow-x: hidden;

  .mat-mdc-dialog-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 clamp(7px, 2vw, 20px);
    width: 100%;
    border-top-left-radius: var(--mdc-dialog-container-shape, var(--mat-sys-corner-extra-large, 4px));
    border-top-right-radius: var(--mdc-dialog-container-shape, var(--mat-sys-corner-extra-large, 4px));
  }

  .title {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
}

@media only screen and (max-width: 299.98px) {
  .ft-dialog-component {
    display: none;
  }
}

@media only screen and ((min-width: 300px) and (max-width: 599.98px)) {
  .ft-dialog-component:not(.auto-size) {
    min-width: 98vw;
    max-width: 98vw;
    width: 98vw;
  }
}

@media only screen and ((min-width: 600px) and (max-width: 959.98px)) {
  .ft-dialog-component:not(.auto-size) {
    width: 88vw;
  }
}

@media only screen and ((min-width: 960px) and (max-width: 1279.98px)) {
  .ft-dialog-component:not(.auto-size) {
    min-width: 800px;
    width: 75vw;
  }
}

@media only screen and ((min-width: 1280px) and (max-width: 1919.98px)) {
  .ft-dialog-component:not(.auto-size) {
    min-width: 950px;
    width: 60vw;
  }
}

@media only screen and ((min-width: 1920px)) {
  .ft-dialog-component:not(.auto-size) {
    width: 60vw;
    min-width: 1150px;
    max-width: 1400px;
  }
}

// .ng-select.ng-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//   border-top: 1px solid grey;
// }

ng-select.select-options {
  display: flex;
  flex: 1;
  width: max-content;

  // displayed value
  .ng-select-container {
    flex: 1;
    display: flex;

    &.ng-has-value {
      color: var(--mat-sys-primary);
    }
  }

  [role='listbox'] {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100% !important;
    min-width: 100% !important;
    .scrollable-content {
      width: 100% !important;
    }
  }

  .ng-dropdown-panel {
    .ng-dropdown-panel-items {
      .scrollable-content {
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: stretch;
        width: fit-content;
        max-width: 100%;
      }

      .ng-option[role='option'] {
        flex: 1;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        display: flex;
        justify-self: stretch;
        width: 100%;
        min-height: 1.8rem;
        overflow-x: visible;

        .ng-option-label {
          max-height: 100%;
          line-height: 1rem;
        }
      }
    }
  }

  .option-card {
    display: flex;
    justify-content: flex-start;
    margin: 0;
    gap: 7px;
    flex: 1;

    text-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis;

    align-items: center;

    line-height: 1.5rem;

    .item-addl-info {
      font-size: small;
      color: var(--mat-sys-inverse-primary);
    }

    div {
      text-wrap: nowrap;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
} // end ng-select

// filter ng-select
.filter-ng-select-container {
  position: relative; /* Important for proper positioning */
  overflow: hidden; /*to prevent scrollbars*/

  .ng-select.ng-select.column-filter {
    padding-bottom: 0px;

    .ng-select-container {
      position: sticky;
      background-color: var(--mat-sys-surface-container-lowest) !important;
      padding: 9px;
      // border-radius: var(--mdc-elevated-card-container-shape, var(--mat-sys-corner-medium));

      border: none !important;

      &::after {
        display: none !important;
      }
    }

    &.ng-select-opened {
      // border-bottom-right-radius: 3px;
      // border-bottom-left-radius: 3px;
      // box-shadow: var(--mat-sys-level2) !important;

      .ng-select-container {
        border-bottom: 1px solid var(--mat-sys-outline) !important;
      }

      ng-dropdown-panel.ng-dropdown-panel {
        position: static;
      }

      [role='listbox'].ng-dropdown-panel-items {
        background-color: var(--mat-sys-surface-container-lowest) !important;
        border: none;
      }
    }
  }

  .ng-select.ng-select.column-filter.ng-select-single {
    .ng-select-container {
      .ng-value-container {
        .ng-value {
          position: absolute;
        }
        .ng-input {
          position: static;
        }
      }
    }
  }
}

// remove underline from filter ng-select
.filter-ng-select-container {
  .mdc-line-ripple,
  .ng-arrow-wrapper {
    border-bottom: none !important;
  }
  .ng-arrow-wrapper::after {
    display: none !important;
  }
}

.user-notification-dialog {
  border-radius: 22px;
  overflow: hidden;
  max-height: 100%;
  height: auto;
  display: flex;

  .mat-mdc-dialog-container {
    height: auto;
  }
}

//
// OpenLayers styles
//
.ol-zoom {
  top: 0.5em !important;
  right: 0.5em !important;
  left: unset !important;
}

.ol-zoom.ol-control {
  z-index: 5;
}

.ol-popup {
  position: absolute;
  background-color: var(--mat-sys-primary-container);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  padding: 12px;
  border-radius: 1vh;
  border: 1px solid var(--mat-sys-color-outline);
  width: clamp(280px, 30vw, 400px);
  display: none;
  z-index: 1000;
  overflow: hidden; /* Hide scrollbars on the popup container itself */
}

.ol-popup.visible {
  display: block;
}

.my-location-control {
  right: 0.5em;
  top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;

  .map-location-button {
    cursor: pointer;
    margin: 1px;
    line-height: normal;
  }
}

ft-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

// removes the underline from material form field inputs

.mat-mdc-form-field {
  .mdc-line-ripple {
    display: none;
  }
}

mat-form-field.tertiary-border {
  .mat-mdc-text-field-wrapper {
    border: 1px solid var(--mat-sys-tertiary-fixed) !important;
  }
}

mat-form-field input::placeholder {
  filter: opacity(40%) !important;
}
